import SmoothScroll from 'smooth-scroll';

var smoothScroll = new SmoothScroll( 'a[href*="#"]', {
	topOnEmptyHash: false,
	updateURL: false,
	speed: 1000,
	speedAsDuration: true,
	offset: function( anchor, toggle ) {
		return 0;
	},
} );
